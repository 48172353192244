<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .talentepool-all {
    .header-intro-generel-box {
      background-position:center center !important;
    }
    
  
   
    .box-top {
      text-align: center;
      border: 10px solid $violet-50per;
      color: $violet-50per;
      padding: 70px 50px;
      position: relative;
      top: 30px;
      left: -450px;
      z-index: 2;
      @media (max-width: 1500px) {
        left: -300px;
      }
      @media (max-width: $width-xl) {
        left: -200px;
      }
      @media (max-width: $width-xl) {
        left: 0px;
        top: 0;
        margin-bottom: 20px;
      }
      @media (max-width: $width-md) {
        padding: 40px 20px;
      }

      .txt-small {
        font-size: 100%;
        letter-spacing: $letter-spacing-std;
        font-weight: 300;
      }
      h3 {
        font-family: 'American Typewriter ITC W04 Md';
        margin-top: 20px;
        font-size: 240%;
        text-transform: none;
        @media (max-width: $width-md) {
          font-size: 140%;
        }
      }
      h4 {
        font-size: 200%;
        @media (max-width: $width-md) {
          font-size: 120%;
        }
      }
    }
    .box-left {
      border: 10px solid $blue-60per;
      display: inline-block;
      background: #fff;
      padding: 40px 70px;
      text-align: center;
      position: relative;
      left: -150px;
      top: -150px;
     
      @media (max-width: 1800px) {
        left: -50px;
      }
      @media (max-width: 1650px) {
        left: 50px;
      }
      @media(max-width:1024px) {
          left:15px;
        }
      @media (max-width: $width-lg) {
        left: 0px;
        top: -100px;
      }
      @media (max-width: $width-md) {
        left: 0px;
        top: 0px;
        margin: 20px auto;
        display: inline-block;
        padding: 20px;
      }
      @media (max-width: $width-sm) {
        width: 100%;
      }

      h3 {
        color: $coral;
        margin-top: 30px;
        font-size: 140%;
        font-family: 'American Typewriter ITC W04 Md';
        line-height: 1.5;
        text-transform: none;
      }
      .txt-small {
        color: $coral;
        margin-top: 120px;
        letter-spacing: $letter-spacing-std;
      }
    }
    .box-right-outer {
      text-align: right;
      .box-right {
        position: relative;
        right: -50px;
        top: -100px;
        background: $mint;
        display: inline-block;
        padding: 30px 15px;

        @media (max-width: 1800px) {
          left: -50px;
        }
        @media(max-width:1024px) {
          left:-15px;
        }
        @media (max-width: $width-lg) {
          left: 0px;
          top: -50px;
        }
        @media (max-width: $width-md) {
          left: 0px;
          top: 0px;
          margin: 20px auto;
          display: inline-block;
        }
        @media (max-width: $width-sm) {
          width: 100%;
          margin: 0px auto;
        }
        .txt-v1,
        .txt-v2 {
          margin-top: 20px;
          text-align: center;
          padding: 15px;
        }
        .txt-v1 {
          font-family: 'American Typewriter ITC W04 Md';
          font-size: 160%;
        }
        .txt-v2 {
          letter-spacing: $letter-spacing-std;
        }
        .img-box {
          padding: 20px;
          position: relative;
          right: -180px;

          @media (max-width: 1650px) {
            right: 0;
          }
          img {
            display: block;
            margin: 0 auto;
            max-width: 200px;
            width: 100%;
            animation: slogan-rotating 15s linear infinite;
          }
          @keyframes slogan-rotating {
            from {
              -ms-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            to {
              -ms-transform: rotate(-360deg);
              -moz-transform: rotate(-360deg);
              -webkit-transform: rotate(-360deg);
              -o-transform: rotate(-360deg);
              transform: rotate(-360deg);
            }
          }
        }
      }
    }
   
    
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="talentepool-all">
    <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-top.jpg')})` }"  :headerImageText="$t('General.talentepool')" />-->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>{{$t('General.karriere')}}</span></div>
              <h1>{{$t('General.talentepool')}}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid margin-b-l">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-sm-10 col-12">
          <div class="highlight-txt-end text-center padding-t-m padding-b-xl">
            <p>{{$t('talentepool.intro-txt')}}</p>
            <!--<div class="btn-std">
            <router-link :to="{ name: 'offene-positionen' }"
              ><span class="txt">Jetzt registrieren</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
            </div>-->
          </div>
        </div>
        <div class="col-lg-8">
           <div class="btn-std margin-t-m">
              <a href="https://karriere.styria.com/Register/34099" target="_blank">
                <span class="txt">{{ $t('talentepool.jetzt-bewerben') }}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
              ></a>
            </div>
        </div>
      </div>
    </div>
    
  <div class="container">
      <div class="row justify-content-center align-self-center">
        <div class="col-xl-10 col-10">
          <div class="box-top" v-inview="['animation-delay-0-5s', 'move-in-from-left']">
            <div class="txt-small">{{$t('General.unsere-vision-title')}}</div>
            <h3 v-html="$t('General.unsere-vision-text')"></h3>
          </div>
        </div>
        <div class="col-lg-12 col-10">
          <div class="main-img-box" v-inview="['animation-delay-0-5s', 'move-in-from-right']">
            <img src="@/assets/img/karriere/image-talent-zeigen.jpg" alt="Talent zeigen" class="img-fluid" />
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-self-center">
        <div class="col-lg-4 col-md-6 col-10">
          <div class="box-left" v-inview="['animation-delay-0-5s', 'move-in-from-top']">
            <h3 v-html="$t('General.unsere-mission-text')"></h3>
            <div class="txt-small">{{$t('General.unsere-mission-title')}}</div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-10 order-lg-2 box-right-outer">
          <div class="box-right" v-inview="['animation-delay-0-5s', 'move-in-from-top']">
            <div class="txt-v1" v-html="$t('General.we-are-as-good')"></div>
            <div class="img-box"><img src="@/assets/img/ueber-uns/slogan-one-spirit-red.svg" alt="One spirit - unlimited ideas" class="img-fluid" /></div>
            <div class="txt-v2" v-html="$t('General.unser-human-title')"></div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <!--<div class="btn-std">
            <router-link :to="{ name: 'was-uns-ausmacht' }"
              ><span class="txt">{{$t('General.mehr-ueber-uns')}}</span> <span class="icon-img"><img src="@/assets/img/icon-arrow-right.svg" alt="Pfeil rechts" class="img-fluid"/></span
            ></router-link>
          </div>-->
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';



import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Talentepool | Styria Media Group',
      keywords: ['talentepool','talent','job','karriere','job','styria media group'],
      description: 'Fehlt der passende ausgeschriebene Job? Dann auf in unseren Talentepool!',
      image: require('@/assets/img/og/karriere.png'),
      
    },
    en: {
      title: 'Talent pool | Styria Media Group',
      keywords: ['talent pool','talent','job','career','styria media group'],
      description: 'Are you missing the right job? Then enter our talent pool!',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,

    
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
